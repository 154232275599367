import {useState, useEffect} from 'react'

export const useLang = (defaultItem = 'en') => {
    
    const [lang, setLang] = useState(defaultItem)

    useEffect(() => {
    
        if(typeof window !== `undefined`){
    
          if(window.location.pathname.split('/')[1] === 'es'){
            setLang('es')
          }
        }
    
      })

      return [lang, setLang]

}
