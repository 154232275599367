import * as React from "react"
import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { GlobalStyle } from "../styles/GlobalStyles"
import Header from "./Header"
// import "./layout.css"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import { TranslatorProvider } from "react-translate"
import { useLang } from "../hooks/useLang"
import { usePages } from "../hooks/usePages"

const Layout = ({ children }) => {
  const [lang, setLang] = useLang("en")

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    // init("user_vgaiuG2MxGaza3rUjDn7B");
    const script = document.createElement("script")

    script.src = "https://smtpjs.com/v3/smtp.js"
    script.async = true

    document.body.appendChild(script)
  })

  const translation = require(`../i18n/${lang}.json`)
  console.log("outside", lang)

  return (
    <TranslatorProvider translations={translation}>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        lang={lang}
        setLang={setLang}
      />
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
      </Helmet>
      <main>{children}</main>
      <Footer />
      <GlobalStyle />
    </TranslatorProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
