import styled from 'styled-components';
import { colorOscuro, fuentePrincipal } from '../variables';

export const FooterContainer = styled.footer`
  background: ${colorOscuro};
`;

export const ImageFooter = styled.div`
  width: 80%;
`;

export const MenuFoter = styled.ul`
  list-style: none;
  padding: 0;
  justify-self: center;
  & li{

    margin-bottom: 1em;

    & a{
        color: white;
        font-family: ${fuentePrincipal};
        text-decoration: none;
    }
  }
`;

export const SocialFooter = styled.div`
  text-align: center;
  align-self: center;
  & h4{
      color: white;
      text-transform: uppercase
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  & a{
      color: white;
      margin-right: 1em;
      font-size: 1.2rem;
      &:last-child{
        margin-right: 0;
      }
  }
`;