import { createGlobalStyle } from "styled-components"
import {
  colorFluerecente,
  colorPrincipal,
  fuentePrincipal,
  fuenteTitulos,
} from "./variables"
// import { fuenteSecundaria, colorOscuro } from "./variables"
// import { nprogressSpinner } from "./animations"

export const GlobalStyle = createGlobalStyle`
  html{
    box-sizing: border-box;
  }
  *,*::before,*::after{
    box-sizing: inherit;
    transition: .3s;
  }
  body{
    font-family: 'Poppins' !important;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-x: hidden;
    position: relative;
    &::-webkit-scrollbar{
      width: 10px;
      background: white;
    }
    &::-webkit-scrollbar-thumb{
      background: ${colorPrincipal};
      border-radius: 5px;
    }
    & #___gatsby{
      overflow-x: hidden;
    }
  }

  body #___gatsby, #gatsby-focus-wrapper{
    overflow: hidden;
  }

  img{
    width: 100%;
  }
  ul,figure{
    margin: 0;
    padding: 0;
  }
  h2{
    font-size: 3rem;

    @media (max-width: 500px){
      font-size: 2rem;
    }
  }
  h2,h3,h4{
    font-family: ${fuenteTitulos};
  }
  h1 p,h2 p,h3 p ,h4 p{
    font-size: inherit;
    margin: 0;
  }
  h2{
    color: ${colorPrincipal};
  }
  h4{
    font-size: 1.2rem;
  }
  p{
    font-family: ${fuentePrincipal};
    font-size: 1.2rem;
  }
  strong{
    color: ${colorFluerecente};
  }
`
