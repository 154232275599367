// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-es-js": () => import("./../../../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-es-js": () => import("./../../../src/pages/contact.es.js" /* webpackChunkName: "component---src-pages-contact-es-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-services-advise-and-consultancy-js": () => import("./../../../src/services/advise-and-consultancy.js" /* webpackChunkName: "component---src-services-advise-and-consultancy-js" */),
  "component---src-services-organizational-structure-js": () => import("./../../../src/services/organizational-structure.js" /* webpackChunkName: "component---src-services-organizational-structure-js" */),
  "component---src-services-product-design-js": () => import("./../../../src/services/product-design.js" /* webpackChunkName: "component---src-services-product-design-js" */),
  "component---src-services-tech-development-js": () => import("./../../../src/services/tech-development.js" /* webpackChunkName: "component---src-services-tech-development-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/Projects.js" /* webpackChunkName: "component---src-templates-projects-js" */)
}

