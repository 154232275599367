import * as React from "react"
import { Link, navigate } from "gatsby"
import { HeaderContainer } from "../styles/components/Header"
import { Container, Dropdown } from "../styles"
import { useState, useEffect } from "react"
import { FaBars } from "react-icons/fa"
import Logo from "../assets/Logo"
import { FaAngleDown } from "react-icons/fa"
import { css } from "styled-components"
import { useLang } from "../hooks/useLang"
import { useTranslate } from "react-translate"

const Header = ({ siteTitle, lang, setLang }) => {
  const [scroll, setScroll] = useState(false)
  const [menuActive, setMenuActive] = useState(false)
  const [url, setUrl] = useState("/es")

  const menuTexts = useTranslate("menu")

  useEffect(() => {
    document.addEventListener("scroll", move)
    if (typeof window !== `undefined`) {
      const $classListButton = document.getElementById("serviceButton")
        .classList

      const isLocationService = window.location.pathname
        .split("/")
        .includes("services")

      if (isLocationService) $classListButton.add("actual-page")
      else $classListButton.remove("actual-page")
    }

    return () => {
      document.removeEventListener("scroll", move)
    }
  }, [scroll, menuActive])
  useEffect(() => {
    handleLanguage()
  }, [lang])

  const move = () => {
    if (typeof window !== `undefined`) {
      const newShowFixed = window.scrollY > 50
      scroll !== newShowFixed && setScroll(newShowFixed)
    }
  }

  const handleMenuActive = () => {
    setMenuActive(!menuActive)
  }

  const handleLanguage = inButton => {
    if (typeof window !== `undefined`) {
      let URL = window.location.pathname
      let urlArray = URL.split("/")

      const isSpanish = urlArray.includes("es")

      if (isSpanish) {
        let urlLength = urlArray.length
        urlArray = urlArray.slice(2, urlLength)
        URL = "/" + urlArray.join("/")
      } else {
        URL = "/es" + URL
      }
      console.log(URL)

      if (inButton) {
        setLang(lang === "en" ? "es" : "en")
        navigate(URL)
      }
    }
  }

  return (
    <HeaderContainer scroll={scroll} isActive={menuActive}>
      <Container>
        <figure>
          <Link to={`/${lang === "es" ? "es" : ""}`}>
            {/* <Image filename={'orbittas-logo.png'} /> */}
            <Logo />
          </Link>
        </figure>
        <button onClick={handleMenuActive}>
          <FaBars />
        </button>
        <nav>
          <ul>
            <li>
              <Link
                activeClassName="actual-page"
                onClick={handleMenuActive}
                to={`${lang === "es" ? "/es" : ""}/about`}
              >
                {menuTexts("about")}
              </Link>
            </li>
            <li>
              <Dropdown>
                <Link
                  css={css`
                    color: white;
                    display: flex;
                    align-items: center;
                  `}
                  id="serviceButton"
                >
                  {" "}
                  {menuTexts("services")} <FaAngleDown />
                </Link>
                <div>
                  <Link
                    onClick={handleMenuActive}
                    to={`${
                      lang === "es" ? "/es" : ""
                    }/services/advise-and-consultancy`}
                  >
                    {menuTexts("advise")}
                  </Link>
                  <Link
                    onClick={handleMenuActive}
                    to={`${
                      lang === "es" ? "/es" : ""
                    }/services/tech-development`}
                  >
                    {menuTexts("tech")}
                  </Link>
                  <Link
                    onClick={handleMenuActive}
                    to={`${lang === "es" ? "/es" : ""}/services/product-design`}
                  >
                    {menuTexts("product")}
                  </Link>
                  <Link
                    onClick={handleMenuActive}
                    to={`${
                      lang === "es" ? "/es" : ""
                    }/services/organizational-structure`}
                  >
                    {menuTexts("organizational")}
                  </Link>
                </div>
              </Dropdown>
            </li>

            <li>
              <Link
                onClick={handleMenuActive}
                to={`${lang === "es" ? "/es" : ""}/portfolio`}
                activeClassName="actual-page"
              >
                {menuTexts("portfolio")}
              </Link>
            </li>
            <li>
              <Link
                onClick={handleMenuActive}
                to={`${lang === "es" ? "/es" : ""}/contact`}
                activeClassName="actual-page"
              >
                {menuTexts("contact")}
              </Link>
            </li>
            <li>
              <button onClick={() => handleLanguage(true)}>
                {lang === "en" ? "es" : "en"}
              </button>
            </li>
          </ul>
        </nav>
      </Container>
    </HeaderContainer>
  )
}

export default Header
