import { graphql, useStaticQuery } from "gatsby"
import { pageFinder } from "../helpers/pageFinder"

export const usePages = pageName => {
  const pages = useStaticQuery(
    graphql`
      query {
        allStrapiPaginas {
          edges {
            node {
              Name
              Cuerpo
            }
          }
        }
      }
    `
  )
  const page = pageFinder(pages, pageName, "allStrapiPaginas")

  return page
}
