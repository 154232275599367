import styled from "styled-components"
import { Link } from "gatsby"
import { colorFluerecente, colorPrincipal, fuentePrincipal } from "./variables"

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 5em 0;

  @media (max-width: 500px) {
    padding: 4em 2em;
  }
`

export const Button = styled(Link)`
  border: 2px solid ${colorFluerecente};
  background: ${props => (props.home ? "transparent" : colorFluerecente)};
  color: ${props => (props.home ? colorFluerecente : "white")};
  font-family: ${fuentePrincipal};
  padding: 0.1em 1em;
  font-size: ${props => {
    if (props.big) return "2rem"
    else return "1.5rem"
  }};
  border-radius: 8px;
  margin: ${props => props.big && "2em auto"};
  display: ${props => props.big && "flex"};
  width: ${props => props.big && "fit-content"};
  text-decoration: none;
  &:hover {
    background: white;
    color: ${colorPrincipal};
    border-color: ${colorPrincipal};
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.custom ? props.custom : "repeat(" + props.columns + ",1fr)"};
  grid-gap: ${props => props.gap + "em"};
  align-items: ${props => props.align};
  & > div:first-child {
    order: ${props => props.invert && "2"};
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    ${props => props.responsive};
  }
`

export const Dropdown = styled.li`
  position: relative;
  display: inline-block;

  & div {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: ${props => props.isFooter && "300px"};
    left: ${props => props.isFooter && "100%"};
    top: ${props => props.isFooter && "0em"};

    & a {
      color: ${colorPrincipal} !important;
      display: block;
      padding: 12px 16px;
      &:hover {
        background: #bbf1fb;
      }
    }
    @media (max-width: 500px) {
      /* left: -2em; */
      z-index: 10003;
      & a {
        font-size: 1.2rem;
      }
    }
  }

  &:hover div {
    display: block;
  }
`

export const PartnerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  filter: grayscale(1) opacity(0.7);
  & .gatsby-image-wrapper {
    width: 100%;
  }

  @media (max-width: 500px) {
    align-items: flex-start;
    height: 300px;
  }
`
