import styled from "styled-components"
import { colorFluerecente, colorPrincipal, fuentePrincipal } from "../variables"

export const HeaderContainer = styled.header`
  position: ${props => (props.scroll ? "fixed" : "absolute")};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  background-color: ${props => props.scroll && colorPrincipal};

  & > div {
    display: flex;
    padding: ${props => (props.scroll ? ".5em 0" : "1em 0")};
    justify-content: space-between;
    align-items: center;

    @media (max-width: 500px) {
      padding: ${props => (props.scroll ? ".5em 2em" : "1em 2em")};
    }

    & figure {
      width: ${props => (props.scroll ? "90px" : "130px")};
    }

    & button {
      display: none;
      background: none;
      border: none;
      color: white;
      font-size: 2rem;
      padding: 0;

      @media (max-width: 500px) {
        display: block;
        z-index: 10001;
        position: relative;
      }
    }

    & nav {
      @media (max-width: 500px) {
        position: absolute;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #001f54d1;
        transform: ${props =>
          props.isActive ? "translateX(0)" : "translateX(-100%)"};
        & ul {
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          z-index: 1000;
          & li {
            margin-bottom: 1rem;
            font-size: 2rem;
          }
        }
      }
    }

    & ul {
      display: flex;
      list-style: none;
      align-items: center;
      & li button {
        display: block;
        text-transform: uppercase;
        font-size: 1rem;
      }
      & a,
      & span {
        font-size: ${props => props.scroll && !props.isActive && ".8rem"};
      }
      & a {
        color: white;
        font-family: ${fuentePrincipal};
        text-decoration: none;
        padding: 0.5em 1em;
        cursor: pointer;
        &.actual-page {
          color: ${colorFluerecente};
        }
      }

      & button {
        background: none;
        color: white;
        font-weight: bold;
        border: none;
        cursor: pointer;
      }
    }
  }
`
