import React from 'react'
import { Container, Dropdown, Grid } from '../styles'
import { FooterContainer, ImageFooter, MenuFoter, SocialFooter, SocialIcons } from '../styles/components/Footer'
import {Link} from 'gatsby'
import { FaAngleDown, FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import Logo from '../assets/Logo'
import { css } from '@emotion/react';
import {useTranslate} from 'react-translate'

export default function Footer() {

    const menuTexts = useTranslate('menu')
    const footerTexts = useTranslate('footer')

    return (
        <FooterContainer>
            <Container>
                <Grid 
                    columns={3} 
                    gap={7}
                    responsive={`
                        gap: 2em;
                    `}
                >
                    <ImageFooter>
                        <Logo/>
                    </ImageFooter>
                    <MenuFoter>
                        <li><Link to="/">{menuTexts('home')}</Link></li>
                        <li><Link to="/about">{menuTexts('about')}</Link></li>
                        <Dropdown isFooter={true}>
                            <span  
                            css={css`
                                color: white;
                                display: flex;
                                align-items: center;
                            `}
                            >Services <FaAngleDown/></span>
                            <div>
                                <Link to='/services/advise-and-consultancy'>{menuTexts('advise')}</Link>
                                <Link to='/services/tech-development'>{menuTexts('tech')}</Link>
                                <Link to='/services/product-design'>{menuTexts('product')}</Link>
                                <Link to='/services/organizational-culture'>{menuTexts('organizational')}</Link>
                            </div>
                        </Dropdown>
                        <li><Link to="/portfolio">{menuTexts('portfolio')}</Link></li>
                        <li><Link to="/contact">{menuTexts('contact')}</Link></li>
                    </MenuFoter>
                    <SocialFooter>
                        <h4>{footerTexts('social')}</h4>
                        <SocialIcons>
                            <a href='https://www.facebook.com/orbittas'   rel="noreferrer" target='_blank'><FaFacebookF/></a>
                            <a href='https://www.instagram.com/orbittas/' rel="noreferrer" target='_blank'><FaInstagram/></a>
                            <a href='https://www.linkedin.com/in/orbittas-technology-08538b210/'  rel="noreferrer" target='_blank'><FaLinkedin/></a>
                        </SocialIcons>
                    </SocialFooter>
                </Grid>
            </Container>
        </FooterContainer>
    )
}
